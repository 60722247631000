import * as React from "react"
import PrivacyStyles from "./Privacy.styles"
import { IProps } from "./Privacy.types"
import { Container, Grid, Typography, withStyles } from "@material-ui/core"

const Privacy = ({ classes }: IProps) => (
  <Grid className={classes.root}>
    <Container>
      <Typography variant="h4" color="textPrimary">
        Privacy Policy<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Effective date: April 23, 2019
        <br/><br/>
        Intivine, Inc. (“us”, “we”, or “our”)
        operates the http://www.getyourshine.com website (hereinafter referred
        to as the “Service”).
        <br/><br/>
        This page informs you of our policies regarding
        the collection, use and disclosure of personal data when you use our
        Service and the choices you have associated with that data.
        <br/><br/>
        We use your
        data to provide and improve the Service. By using the Service, you agree
        to the collection and use of information in accordance with this policy.
        Unless otherwise defined in this Privacy Policy, the terms used in this
        Privacy Policy have the same meanings as in our Terms and Conditions,
        accessible from http://www.getyourshine.com
      </Typography>
      <Typography variant="h4" color="textPrimary">
        <br/>Definitions<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>-Service<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Service is the http://www.getyourshine.com website operated by Intivine, Inc.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>-Personal Data<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Personal Data means data about a living individual who can be identified
        from those data (or from those and other information either in our possession
        or likely to come into our possession).<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>-Usage Data<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Usage Data is data collected automatically either generated by the
        use of the Service or from the Service infrastructure itself (for example,
        the duration of a page visit).<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>-Cookies<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Cookies are small files stored on your device (computer or mobile device).<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>-Data Controller<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Data Controller means the natural or legal person who (either alone
        or jointly or in common with other persons) determines the purposes for
        which and the manner in which any personal information are, or are to be, processed.<br/><br/>
        For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>-Data Processors (or Service Providers)<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Data Processor (or Service Provider) means any natural or legal person
        who processes the data on behalf of the Data Controller.<br/><br/>
        We may use the services of various Service Providers in order to process your data more effectively.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>-Data Subject (or User<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Data Subject is any living individual who is using our Service and is the subject of Personal Data.<br/><br/>
      </Typography>
      <Typography variant="h4" color="textPrimary">
        Information Collection and Use<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>We collect several different types of information for various purposes to provide and improve our Service to you.<br/>
      </Typography>
      <Typography variant="h4" color="textPrimary">
        <br/>Types of Data Collected<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Personal Data<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or identify
        you (“Personal Data”). Personally identifiable information may include, but is not limited to:<br/><br/>
        - Email address<br/><br/>
        - First name and last name<br/><br/>
        - Phone number<br/><br/>
        - Address, State, Province, ZIP/Postal code, City<br/><br/>
        - Cookies and Usage Data<br/><br/>
        We may use your Personal Data to contact you with newsletters, marketing
        or promotional materials and other information that may be of interest to
        you. You may opt out of receiving any, or all, of these communications
        from us by following the unsubscribe link or instructions provided in
        any email we send or by contacting us.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Usage Data<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>We may also collect information on how the Service is accessed and
        used (“Usage Data”). This Usage Data may include information such as your
        computer’s Internet Protocol address (e.g. IP address), browser type,
        browser version, the pages of our Service that you visit, the time and
        date of your visit, the time spent on those pages, unique device identifiers
        and other diagnostic data.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Tracking & Cookies Data<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.<br/><br/>
        Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent
        to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons,
        tags and scripts to collect and track information and to improve and analyse our Service.<br/><br/>
        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you
        do not accept cookies, you may not be able to use some portions of our Service.<br/><br/>
        Examples of Cookies we use:<br/>
        - <b>Session Cookies:</b> We use Session Cookies to operate our Service.<br/>
        - <b>Preference Cookies:</b> We use Preference Cookies to remember your preferences and various settings.<br/>
        - <b>Security Cookies:</b> We use Security Cookies for security purposes.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Use of Data<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Intivine, Inc. uses the collected data for various purposes:<br/><br/>
        - To provide and maintain our Service<br/>
        - To notify you about changes to our Service<br/>
        - To allow you to participate in interactive features of our Service when you choose to do so<br/>
        - To provide customer support<br/>
        - To gather analysis or valuable information so that we can improve our Service<br/>
        - To monitor the usage of our Service<br/>
        - To detect, prevent and address technical issues<br/>
        - To provide you with news, special offers and general information about other goods,
        services and events which we offer that are similar to those that you have already
        purchased or enquired about unless you have opted not to receive such information<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>If you are from the European Economic Area (EEA), Intivine, Inc.
        legal basis for collecting and using the personal information described
        in this Privacy Policy depends on the Personal Data we collect and the
        specific context in which we collect it.<br/><br/>
        Intivine, Inc. may process your Personal Data because:<br/><br/>
        - We need to perform a contract with you<br/>
        - You have given us permission to do so<br/>
        - The processing is in our legitimate interests and it is not overridden by your rights<br/>
        - For payment processing purposes<br/>
        - To comply with the law<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Retention of Data<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Intivine, Inc. will retain your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will retain
        and use your Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to comply
        with applicable laws), resolve disputes and enforce our legal agreements and policies.<br/><br/>
        Intivine, Inc. will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except when
        this data is used to strengthen the security or to improve the functionality
        of our Service, or we are legally obligated to retain this data for longer
        periods.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Transfer of Data<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Your information, including Personal Data, may be transferred to —
        and maintained on — computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection laws
        may differ from those of your jurisdiction.<br/><br/>
        If you are located outside United States and choose to provide information
        to us, please note that we transfer the data, including Personal Data,
        to United States and process it there.<br/><br/>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.<br/><br/>
        Intivine, Inc. will take all the steps reasonably necessary to ensure
        that your data is treated securely and in accordance with this Privacy
        Policy and no transfer of your Personal Data will take place to an organisation
        or a country unless there are adequate controls in place including the
        security of your data and other personal information.<br/>
      </Typography>
      <Typography variant="h4" color="textPrimary">
        <br/>Disclosure of Data<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Disclosure for Law Enforcement<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Under certain circumstances, Intivine, Inc. may be required to
        disclose your Personal Data if required to do so by law or in response
        to valid requests by public authorities (e.g. a court or a government agency).
        <br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Legal Requirements<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Intivine, Inc. may disclose your Personal Data in the good faith belief that such action is necessary to:<br/><br/>
        - To comply with a legal obligation<br/>
        - To protect and defend the rights or property of Intivine, Inc.<br/>
        - To prevent or investigate possible wrongdoing in connection with the Service<br/>
        - To protect the personal safety of users of the Service or the public<br/>
        - To protect against legal liability<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Security of Data<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>The security of your data is important to us but remember that no
        method of transmission over the Internet or method of electronic storage
        is 100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Our Policy on “Do Not Track” Signals under the California Online Protection Act (CalOPPA)<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>We do not support Do Not Track (“DNT”). Do Not Track is a preference
        you can set in your web browser to inform websites that you do not want to be tracked.<br/><br/>

        You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Your Data Protection Rights under the General Data Protection Regulation (GDPR)<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights. Intivine, Inc. aims to take reasonable
        steps to allow you to correct, amend, delete or limit the use of your Personal Data.<br/><br/>
        If you wish to be informed about what Personal Data we hold about you and
        if you want it to be removed from our systems, please contact us.<br/><br/>
        In certain circumstances, you have the following data protection rights:<br/>
        - <b>The right to access, update or delete the information we have on you. </b>
         Whenever made possible, you can access, update or request deletion of your
        Personal Data directly within your account settings section. If you are
        unable to perform these actions yourself, please contact us to assist you.<br/><br/>
        - <b>The right of rectification.</b> You have the right to have your
        information rectified if that information is inaccurate or incomplete.<br/><br/>
        - <b>The right to object.</b> You have the right to object to our processing
        of your Personal Data.<br/><br/>
        - <b>The right of restriction.</b> You have the right to request that we
        restrict the processing of your personal information.<br/><br/>
        - <b>The right to data portability.</b> You have the right to be provided
        with a copy of the information we have on you in a structured, machine-readable
        and commonly used format.<br/><br/>
        - <b>The right to withdraw consent.</b> You also have the right to withdraw
        your consent at any time where Intivine, Inc. relied on your consent to
        process your personal information.<br/><br/>
        Please note that we may ask you to verify your identity before responding to such requests.<br/><br/>
        You have the right to complain to a Data Protection Authority about our
        collection and use of your Personal Data. For more information, please
        contact your local data protection authority in the European Economic Area (EEA).<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Service Providers<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>We may employ third party companies and individuals to facilitate
        our Service (“Service Providers”), provide the Service on our behalf,
        perform Service-related services or assist us in analysing how our Service is used.<br/><br/>
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it for
        any other purpose.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Analytics<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>We may use third-party Service Providers to monitor and analyse the use of our Service.<br/><br/>
        - <b>Google Analytics</b><br/><br/>
        Google Analytics is a web analytics service offered by Google that tracks
        and reports website traffic. Google uses the data collected to track and
        monitor the use of our Service. This data is shared with other Google
        services. Google may use the collected data to contextualise and personalise
        the ads of its own advertising network.<br/><br/>
        You can opt-out of having made your activity on the Service available to
        Google Analytics by installing the Google Analytics opt-out browser add-on.
        The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js)
        from sharing information with Google Analytics about visits activity.<br/><br/>
        For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page <a href="https://policies.google.com/privacy?hl=en">here</a><br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Behavioral Remarketing<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Intivine, Inc. uses remarketing services to advertise on third party
        websites to you after you visited our Service. We and our third-party
        vendors use cookies to inform, optimise and serve ads based on your past
        visits to our Service.<br/><br/>
        - <b>Google Ads (AdWords)</b><br/><br/>
        Google Ads (AdWords) remarketing service is provided by Google Inc.<br/><br/>
        You can opt-out of Google Analytics for Display Advertising and customise
        the Google Display Network ads by visiting the Google Ads Settings page <a href="http://www.google.com/settings/ads">here</a><br/><br/>
        Google also recommends installing the Google Analytics Opt-out Browser
        Add-on – <a href="https://tools.google.com/dlpage/gaoptout">here</a> –
        for your web browser. Google Analytics Opt-out Browser Add-on provides
        visitors with the ability to prevent their data from being collected
        and used by Google Analytics.<br/><br/>
        For more information on the privacy practices of Google,
        please visit the Google Privacy & Terms web page <a href="https://policies.google.com/privacy?hl=en">here</a><br/><br/>
        - <b>Facebook</b><br/><br/>
        Facebook remarketing service is provided by Facebook Inc.<br/><br/>
        You can learn more about interest-based advertising from Facebook by visiting <a href="https://www.facebook.com/help/164968693837950">this page</a>.<br/><br/>
        To opt-out from Facebook’s interest-based ads, follow <a href="https://www.facebook.com/help/568137493302217">these instructions</a> from Facebook.<br/><br/>
        Facebook adheres to the Self-Regulatory Principles for Online Behavioural
        Advertising established by the Digital Advertising Alliance. You can
        also opt-out from Facebook and other participating companies through the
        Digital Advertising Alliance in the USA <a href="http://www.aboutads.info/choices/">here</a>
        , the Digital Advertising Alliance of Canada in Canada <a href='http://youradchoices.ca/'>here</a> or
        the European Interactive Digital Advertising Alliance in Europe <a href="http://www.youronlinechoices.eu/">here</a>
        , or opt-out using your mobile device settings.<br/><br/>
        For more information on the privacy practices of Facebook, please visit
        Facebook’s Data Policy <a href="https://www.facebook.com/privacy/explanation">here</a><br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Payments<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>We may provide paid products and/or services within the Service. In
        that case, we use third-party services for payment processing (e.g. payment processors).<br/><br/>
        We will not store or collect your payment card details. That information
        is provided directly to our third-party payment processors whose use of
        your personal information is governed by their Privacy Policy. These payment
        processors adhere to the standards set by PCI-DSS as managed by the PCI Security
        Standards Council, which is a joint effort of brands like Visa, MasterCard,
        American Express and Discover. PCI-DSS requirements help ensure the secure
        handling of payment information.<br/><br/>
        The payment processors we work with are:<br/><br/>
        - <b>Stripe</b><br/><br/>
        Their Privacy Policy can be viewed <a href="https://stripe.com/us/privacy">here</a><br/><br/>
        - <b>PayPal / Braintree</b><br/><br/>
        Their Privacy Policy can be viewed <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">here</a><br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Links to Other Sites<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Our Service may contain links to other sites that are not operated
        by us. If you click a third party link, you will be directed to that
        third party’s site. We strongly advise you to review the Privacy Policy
        of every site you visit.<br/><br/>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Children's Privacy<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>Our Service does not address anyone under the age of 18 (“Children”).<br/><br/>

        We do not knowingly collect personally identifiable information from anyone
        under the age of 18. If you are a parent or guardian and you are aware
        that your Child has provided us with Personal Data, please contact us.
        If we become aware that we have collected Personal Data from children without
        verification of parental consent, we take steps to remove that information
        from our servers.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Changes to This Privacy Policy<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>We may update our Privacy Policy from time to time. We will notify
        you of any changes by posting the new Privacy Policy on this page.<br/><br/>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the “effective date”
        at the top of this Privacy Policy.<br/><br/>
        You are advised to review this Privacy Policy periodically for any changes.
        Changes to this Privacy Policy are effective when they are posted on this page.<br/>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        <br/>Contact Us<br/>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <br/>If you have any questions about this Privacy Policy, please contact us:<br/><br/>
        <a href="mailto:info@getyourshine.com">info@getyourshine.com</a>
      </Typography>
    </Container>
  </Grid>
)

export default withStyles(PrivacyStyles)(Privacy)
